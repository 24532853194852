<template>
    <div>
        <div class="main_back"> 
            <div class="hor">
                <div style="line-height:40px;background:#808695;color:white;border-left:3px solid #389">邮箱参数设定</div>
                <div style="display:flex;margin-top:20px">
                    <Form  :model="mailRow"  :label-width="150" style="width:1200px"  label-position="right" inline > 
                        <span v-for="(item,index) in mailCol" :key="index" >
                            <FormItem :label="item.title "  class="item-width" v-if="item.fhide==='N'" :prop="item.key">               
                                <el-select v-model="mailRow[item.key]"   v-if="item.key==='SENDMTH'" style="width:100%">                   
                                    <el-option  label="SMTP" value="smtp" ></el-option> 
                                </el-select>  
                                <el-checkbox v-else-if="item.key==='VALID'" border true-label="Y" false-label="N" v-model="mailRow[item.key]" style="width:100%"></el-checkbox>                   
                                <el-select v-model="mailRow[item.key]"   v-else-if="item.key==='SSL'" style="width:100%">                   
                                    <el-option  label="SSL" value="SSL" ></el-option> 
                                </el-select>    
                                <el-input  v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="mailRow[item.key]" ></el-input>
                            </FormItem>
                        </span>
                    </Form>
                </div>
                <div style="display:flex;margin-top:20px;line-height:30px; ">
                    <span style="line-height:30px; padding-left:5px">发送一封测试邮件：</span>
                    <el-checkbox  border true-label="Y" false-label="N" v-model="istest" style="width:80px">是</el-checkbox>   
                    <el-input type="text"   v-model="testmail" style="width:300px"></el-input> 
                    <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="saveRec" style="margin-right:10px;">保存</el-button>         
                </div>
            </div>
        </div>
        <!-- 提示框 -->
        <el-dialog :visible.sync="promptWin" width="450px" append-to-body>
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">提示</span>
            </div>
            <div   >{{prompt}} </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
 
import {getBaseData,getTabColOrData} from '../../api/user' 
export default ({
    name:'mailbox_set',
    data () {
        return {
            istest:'N',
            testmail:'',
            mailRow:{},
            mailCol:[],
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径  
            promptWin:false,
            prompt:'',
        }
    },
    created () {
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','112','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
            for (let k=0; k<res.data.length; k++){
                this.mailCol.push({ //向数组的开头 添加序列号
                    title: res.data[k].title,
                    key: res.data[k].key,
                    align: res.data[k].align,
                    width: res.data[k].width,  
                    thide: res.data[k].THIDE,  
                    fhide: res.data[k].FHIDE,
                    disabled: res.data[k].DISABLED,  
                    edcss: res.data[k].EDCSS,                 
                })
            }
        }) 
    },
    mounted () {
          
        getBaseData(this.$store.state.nologinUrl, '','','','','v_mailset','').then(res=>{
            this.mailRow=res.data.result[0]
        })     
    },
    methods: {
        //保存记录并发送邮件
        saveRec(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username +'&password='+this.v_password ,
                data: {p_json:JSON.stringify(this.mailRow), p_table:'mailset'},
            }).then(res=>{      
                if(res.data.result =='OK')  {          
                    if (this.istest=='Y'){
                        var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
                        if (!verify.test(this.testmail)) {
                            this.promptWin=true
                            this.prompt ='邮箱格式错误, 请重新输入'
                        }else{
                           this.$axios({
                                method: 'post',
                                url:this.$store.state.baseUrl+'user/sendReqMail?username='+this.v_username +'&password='+this.v_password ,
                                data: {title:'邮箱参数设定测试',mailaddr:this.testmail,p_note:'邮箱参数设定正确，已成功发送邮件！'},
                            }).then(res=>{ 
                                if (res.data.code=='200'){
                                    this.promptWin=true
                                    this.prompt ='邮件已发送，请到邮箱查看' 
                                }else{
                                    this.promptWin=true
                                    this.prompt =res.data.message
                                }
                            })
                        }
                    }else{
                        this.promptWin=true
                        this.prompt ='数据已保存'
                    }      
                }else{
                    this.prompt =res.data.result
                    this.promptWin=true
                }        
            }) 
        }
 
    }
})
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
.main_back{
 
    width:100%;
    background: white;
}
.main_back .hor{
    text-align:center;
    background: white;
    font-weight: 700;
    font-size: 14px;
}
.item-width{
    line-height:40px;
    background:rgb(195, 237, 243);
    width:48%;
}
</style>
